import React from 'react'
import Lightbox from 'react-image-lightbox'
import { Helmet } from 'react-helmet'

import SocialLinks from '../components/SocialLinks/SocialLinks'
import ConfidentSpeakers from '../components/ConfidentSpeakers/ConfidentSpeakers'
import StudentsAndEducators from '../components/StudentsAndEducators/StudentsAndEducators'
import KeyFeatures from '../components/KeyFeatures/KeyFeatures'

import { metaData } from '../meta'

const detailedStudentReportsCarouselImages = [
  'https://d1syj4d8txnu77.cloudfront.net/Education/1_Recordings_SpeechesTable.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/2_Details.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/3_Transcript.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/4_Factors.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/5_Quotes.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/6_Tips.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/Education/7_Analytics.jpg'
]

class EudcationIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      photoIndex: 0,
      section: 0
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  openLightboxWithImageIndex(index, section) {
    this.setState({
      isOpen: true,
      photoIndex: index,
      section: section
    })
  }

  renderLightbox(isOpen, photoIndex, section) {
    if (typeof window !== 'undefined') {
      return (
        <div>
          {isOpen &&
            (section === 0 ? (
              <Lightbox
                mainSrc={detailedStudentReportsCarouselImages[photoIndex]}
                nextSrc={
                  detailedStudentReportsCarouselImages[(photoIndex + 1) % detailedStudentReportsCarouselImages.length]
                }
                prevSrc={
                  detailedStudentReportsCarouselImages[
                    (photoIndex + detailedStudentReportsCarouselImages.length - 1) %
                      detailedStudentReportsCarouselImages.length
                  ]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + detailedStudentReportsCarouselImages.length - 1) %
                      detailedStudentReportsCarouselImages.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % detailedStudentReportsCarouselImages.length
                  })
                }
              />
            ) : (
              <></>
            ))}
        </div>
      )
    }
  }

  render() {
    const { isOpen, photoIndex, section } = this.state
    return (
      <div style={{ background: '#fff' }}>
        <Helmet title={metaData.education.title}>
          <meta name="description" content={metaData.education.description} />
          <meta name="keywords" content={metaData.education.keywords} />
        </Helmet>
        <StudentsAndEducators />
        <KeyFeatures
          openLightboxWithImageIndex={this.openLightboxWithImageIndex.bind(this)}
          reportsCarouselImages={detailedStudentReportsCarouselImages}
        />
        <ConfidentSpeakers />
        <SocialLinks />
        {this.renderLightbox(isOpen, photoIndex, section)}
      </div>
    )
  }
}

export default EudcationIndex
