import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './FlexibleConcurrentLicensing.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/FlexibleConcurrentLicensing.jpg'

export default class FlexibleConcurrentLicensing extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6 order-md-1">
          <h3 className="show-on-mobile">Flexible Concurrent Licensing</h3>
          <StaticImage
            className="img"
            src={image}
            alt="Flexible Concurrent Licensing"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="col-md-6 order-md-2">
          <h3 className="hide-on-mobile">Flexible Concurrent Licensing</h3>
          <br />
          <h4>
            No need to worry about how many devices you install Ovation on (unlimited) or how many students can create
            accounts (also unlimited).
            <br /> <br />
            Simply estimate your peak{' '}
            <a target="_blank" href="https://docs.ovationvr.com/ovation/creating-an-account/computer-licenses">
              concurrent users
            </a>
            , then scale up as your deployment grows.
          </h4>
        </div>
      </div>
    )
  }
}
